import { css } from 'styled-components';
import { borderRadius, color, fontSize, height, lineHeight, margin, padding, rgbaColor, screen } from '../mixins';
const typographyStyles = css `
	// Typography
	// - strong -> semi bold
	.ant-typography strong {
		font-weight: 600;
	}

	h5.ant-typography {
		margin-bottom: ${margin('sm')};
	}

	.ant-typography {
		${screen.lte.sm `
			overflow-wrap: normal;
		`}
	}
`;
const layoutStyles = css `
	.ant-layout-header {
		border-bottom: 1px solid ${color(['primary', '200'])};
		background: ${color('white')};

		${screen.lte.xs `
			height: ${height('xl')};
		`}
	}

	.ant-affix {
		z-index: 2;
	}
`;
export const ANT_TABLE_MOBILE_CARDS_CLASS_NAME = 'ant-table-mobile-cards';
export const ANT_TABLE_TRIM_OUTER_PADDING_CLASS_NAME = 'ant-table-trim-outer-padding';
export const ANT_TABLE_AUTO_LAYOUT_CLASS_NAME = 'ant-table-auto-layout';
export const ANT_TABLE_MOBILE_SHOW_HEAD_CLASS_NAME = 'ant-table-mobile-show-head';
const tableStyles = css `
	.ant-table table {
		table-layout: fixed !important;
		width: 100%;

		.ant-empty {
			min-height: 73px;
		}
	}

	.ant-table.${ANT_TABLE_AUTO_LAYOUT_CLASS_NAME} table,
	.${ANT_TABLE_AUTO_LAYOUT_CLASS_NAME} .ant-table table {
		table-layout: auto !important;
	}

	.ant-table-thead > tr > th {
		font-weight: 600;
		border-bottom: none;
	}

	.ant-table-thead {
		${screen.lte.md({
    display: 'none',
})}
	}

	.${ANT_TABLE_MOBILE_SHOW_HEAD_CLASS_NAME} .ant-table-thead {
		${screen.lte.md({
    display: 'table-header-group',
})}
	}

	.ant-table-tbody > tr > td {
		${screen.lte.md `
			padding: ${padding('md')};
		`}
	}

	.${ANT_TABLE_TRIM_OUTER_PADDING_CLASS_NAME} {
		.ant-table-thead > tr > th {
			padding-top: 0;
		}

		.ant-table-thead > tr > th,
		.ant-table-tbody > tr > td {
			&:first-child {
				padding-left: 0;
			}

			&:last-child {
				padding-right: 0;
			}
		}

		.ant-table-tbody > tr:first-child > td {
			${screen.lte.md({
    paddingTop: 0,
})}
		}
	}

	.${ANT_TABLE_MOBILE_CARDS_CLASS_NAME} .ant-table-tbody > tr > td {
		${screen.lte.md `
			border-bottom: ${margin('sm')} solid ${color(['primary', '000'])};
		`}
	}

	.ant-table-column-sorter {
		margin-top: 0;
	}

	.ant-table-column-sorter-up,
	.ant-table-column-sorter-down {
		font-size: ${fontSize('xl')};
	}

	.ant-table-selection-column {
		> .ant-table-selection,
		> .ant-checkbox-wrapper {
			position: absolute;
			top: 50%;
			left: 0;
			transform: translateY(-50%);
		}

		.ant-checkbox-inner {
			width: ${height('xxs')};
			height: ${height('xxs')};
		}

		.ant-checkbox-indeterminate {
			.ant-checkbox-inner {
				&:after {
					width: ${height('xxs')};
					height: ${height('xxs')};
					border-radius: 6px;
				}
			}
		}

		.ant-checkbox-checked {
			.ant-checkbox-inner {
				&:after {
					width: calc(${padding('xxs')} - 1px);
					height: calc(${padding('xs')} + 1px);
				}
			}
		}
	}

	td {
		&.ant-table-column-sort {
			background: transparent;
		}
	}
`;
const stepsStyles = css `
	.ant-steps-item-container {
		display: flex;
		align-items: baseline;
	}

	.ant-steps-item-icon {
		flex-shrink: 0;
	}

	.ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-icon {
		background: ${color('primary')};
		border-color: ${color('primary')};
	}

	.ant-steps-item-finish .ant-steps-item-icon {
		border-color: ${color('primary')} !important;

		> .ant-steps-icon {
			color: ${color('primary')} !important;
		}
	}

	.ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title::after {
		background-color: ${color('primary')} !important;
	}

	.ant-steps
		.ant-steps-item:not(.ant-steps-item-active)
		> .ant-steps-item-container[role='button']:hover
		.ant-steps-item-title,
	.ant-steps
		.ant-steps-item:not(.ant-steps-item-active)
		> .ant-steps-item-container[role='button']:hover
		.ant-steps-item-subtitle,
	.ant-steps
		.ant-steps-item:not(.ant-steps-item-active)
		> .ant-steps-item-container[role='button']:hover
		.ant-steps-item-description {
		color: ${color('primary')} !important;
	}

	.ant-steps-vertical {
		display: flex;
		align-items: flex-start;
		width: auto;

		> .ant-steps-item {
			flex: 0 0 auto;
		}
	}

	.ant-steps-horizontal {
		${screen.lte.sm `
			width: auto;
		`}

		.ant-steps-item-container {
			${screen.lte.md `
				align-items: center;
			`}
		}

		.ant-steps-item.ant-steps-item-active {
			${screen.lte.md `
				flex: 1 0 auto;
			`}

			${screen.lte.sm `
				flex: 0 0 auto;
				padding-left: 0;
			`}
		}

		.ant-steps-item:not(.ant-steps-item-active) {
			.ant-steps-item-title {
				${screen.lte.md `
					font-size: 0;
				`}
			}

			${screen.lte.sm `
				display: none;
			`}
		}

		.ant-steps-item-title {
			${screen.lte.sm `
				&:after {
					display: none;
				}
			`}
		}
	}
`;
export const ANT_CARD_FLEX_BODY_HEIGHT_CLASS_NAME = 'ant-card-flex-body-height';
export const ANT_CARD_HIDDEN_TITLE_CLASS_NAME = 'ant-card-hidden-title';
const cardStyles = css `
	// Card: restyle heading to align with wireframes
	.ant-card {
		display: flex;
		flex-direction: column;
		height: 100%;
	}

	.ant-card,
	.ant-card-head {
		border: none;
		background: none;
	}

	.ant-card-head,
	.ant-card-small .ant-card-head {
		flex: 0;
		padding: 0;
		margin-bottom: ${margin('sm')};
		min-height: auto;
	}

	.ant-card-head-title,
	.ant-card-small > .ant-card-head > .ant-card-head-wrapper > .ant-card-head-title {
		font-size: ${fontSize('xxl')};
		font-weight: 600;
	}

	.ant-card-small > .ant-card-head > .ant-card-head-wrapper > .ant-card-head-title,
	.ant-card-head-title,
	.ant-card-extra {
		padding: 0;
	}

	.ant-card-body {
		flex: 1;
		border: 1px solid ${color(['primary', '200'])};
		border-radius: ${borderRadius('sm')};
		background-color: ${color('white')};
		position: relative;
	}

	&.${ANT_CARD_FLEX_BODY_HEIGHT_CLASS_NAME} .ant-card-body {
		flex: 0;
	}

	&.${ANT_CARD_HIDDEN_TITLE_CLASS_NAME} .ant-card-head-title {
		visibility: hidden;
		user-select: none;
	}

	.ant-card-head-wrapper,
	.ant-card-small .ant-card-head-wrapper {
		${screen.lte.xs({
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start',
})}

		.ant-card-extra {
			${screen.lte.xs({
    float: 'none',
    marginLeft: 0,
})}
		}

		.ant-card-head-title {
			${screen.lte.xs({
    maxWidth: '100%',
    whiteSpace: 'pre-wrap',
})}
		}
	}

	.ant-card .ant-card-body {
		${screen.lte.xs `
			padding: ${padding('lg')};
		`}
	}

	.ant-card.ant-card-small .ant-card-body {
		${screen.lte.xs `
			padding: ${padding('md')};
		`}
	}
`;
const avatarStyles = css `
	// Increase font weight on avatar
	.ant-avatar > span {
		font-weight: 500;
	}
`;
export const ANT_MODAL_VERTICAL_FOOTER_CLASS_NAME = 'ant-modal-vertical-footer';
const modalStyles = css `
	.ant-modal {
		margin: 0 auto !important;
		padding: 0 !important;
		${screen.lte.md `
			width: 90vw !important;
			min-width: auto;
			max-width: none;
		`}
	}

	.ant-modal-content {
		min-width: 660px;
		border-radius: ${borderRadius('md')};

		${screen.lte.md `
			width: 100%;
			min-width: auto;
		`}
	}

	.ant-modal-header {
		padding: ${padding('xl')} 100px ${padding('xl')} ${padding('xl')}; // to avoid close icon text overlapping

		${screen.lte.xs `
			padding: ${padding('lg')};
		`}
	}

	.ant-modal-body {
		padding: 0 ${padding('xl')} ${padding('xl')};

		${screen.lte.xs `
			padding: ${padding(0, 'lg', 'lg')};
		`}
	}

	.ant-modal-footer {
		display: flex;
		justify-content: center;
		padding-top: 0 !important;

		${screen.lte.xs `
			padding: ${padding('lg')};
		`}

		// When a button is the only child of the footer
		// make sure the width is adjusted properly
		> button:first-child:last-child {
			min-width: 300px;

			${screen.lte.xs `
				min-width: auto;
			`}
		}

		> button {
			margin: 0 ${margin('xs')} 0 0;
			&:last-child {
				margin: 0;
			}
		}
	}

	.ant-modal-close {
		top: ${padding('xl')};
		right: ${padding('xl')};
		border: 1px solid ${color(['primary', '200'])};
		border-radius: ${borderRadius('sm')};
		transition: all 0.3;
		background: ${color('white')};
		color: ${color('primary')};

		${screen.lte.md `
			top: 0 !important;
			right: 0 !important;
			transform: translate(25%, -25%);
			border-radius: 50%;
			background: ${color(['gray', '800'])};
			border-color: ${color(['gray', '700'])};
			color: ${color('white')};
		`}

		.ant-modal-close-x {
			display: flex;
			align-items: center;
			justify-content: center;

			${screen.lte.md `
				width: ${height('xs')};
				height: ${height('xs')};
				line-height: ${height('xs')};
				font-size: ${fontSize('xs')};
			`}
		}

		&:hover,
		&:active,
		&:focus {
			color: ${color('white')};
			background-color: ${color('primary')};

			${screen.lte.md `
				background: ${color(['gray', '800'])};
			`}
		}
	}

	.${ANT_MODAL_VERTICAL_FOOTER_CLASS_NAME} .ant-modal-footer {
		flex-direction: column;
		justify-content: flex-start;
		align-items: center;
		padding: ${padding('xl', 'lg')};
		> button {
			min-width: 300px;
			margin: 0 0 ${margin('md')} 0 !important;
			&:last-child {
				margin: 0 !important;
			}

			${screen.lte.xs `
				min-width: auto;
			`}
		}
	}

	.ant-modal-footer:empty {
		display: none;
	}

	.ant-modal-title {
		${screen.lte.xs `
			line-height: ${lineHeight()};
		`}
	}
`;
export const ANT_FORM_ITEM_PRESERVE_MARGIN_CLASS_NAME = 'ant-form-item-preserve-margin';
const formStyles = css `
	// Fix required mark being position before the label (since we are using vertical forms)
	.ant-form-item-required {
		display: flex;
		flex-direction: row-reverse;
		justify-content: flex-end;
	}

	// Make sure vertical form has display flex
	.ant-form-vertical {
		display: flex;
		flex-direction: column;
	}

	// Make input errors slightly smaller
	.ant-form-item-explain-error [role='alert'] {
		font-size: ${fontSize('sm')};
		padding: 2px 0;
		display: flex;

		&:before {
			display: inline-block;
			content: '*';
			margin-right: ${margin('xxs')};
		}
	}

	.ant-form-item:not(.${ANT_FORM_ITEM_PRESERVE_MARGIN_CLASS_NAME}):last-child {
		margin-bottom: 0;
	}

	.ant-form-item-label svg {
		flex: 0 0 auto;
	}
`;
const radioStyles = css `
	.ant-radio-wrapper {
		align-items: center;
	}

	.ant-radio {
		top: 0;

		:hover {
			.ant-radio-inner {
				border-color: ${color('primary')};
			}
		}
	}

	.ant-radio-input {
		:focus + .ant-radio-inner {
			box-shadow: 0 0 0 3px ${rgbaColor('primary', 0.08)};
			border-color: ${color('primary')};
		}
	}

	.ant-radio-group {
		display: flex;
	}

	.ant-radio-checked .ant-radio-inner {
		border-color: ${color('primary')};

		:after {
			background-color: ${color('primary')};
		}
	}

	.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before,
	.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover::before {
		background-color: ${color('primary')};
	}

	.ant-radio-button-wrapper:hover {
		color: ${color('primary')};
	}

	.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled),
	.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover {
		color: ${color('primary')};
		border-color: ${color('primary')};
	}

	.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):first-child {
		border-color: ${color('primary')};
	}

	.ant-radio-button-wrapper-checked:not(
			[class*=' ant-radio-button-wrapper-disabled']
		).ant-radio-button-wrapper:first-child {
		border-right-color: ${color('primary')};
	}

	.ant-radio-button-wrapper {
		display: flex;
		align-items: center;
		justify-content: center;
		> span:not(.ant-radio-button) {
			display: flex;
			justify-content: center;
			align-items: center;
		}
	}
`;
const checkboxStyles = css `
	// Fix checkbox & checkbox label not being properly aligned
	.ant-checkbox-wrapper {
		align-items: center;

		> span {
			padding: 0;
		}
	}

	.ant-checkbox {
		top: 0;
		margin-right: ${margin('xxs')};
	}

	.ant-checkbox-wrapper:hover .ant-checkbox-inner,
	.ant-checkbox:hover .ant-checkbox-inner,
	.ant-checkbox-input:focus + .ant-checkbox-inner {
		border-color: ${color('primary')};
	}

	.ant-checkbox-checked .ant-checkbox-inner {
		background: ${color('primary')};
		border-color: ${color('primary')};
		color: ${color(['primary', '900'])};
	}
`;
const switchStyles = css `
	// Fix switch input handle being out of the parent container
	.ant-switch-checked .ant-switch-handle {
		left: 24px;
	}

	.ant-switch-checked {
		background-color: ${color('primary')};
	}
`;
export const ANT_BUTTON_AUTO_WIDTH_CLASS_NAME = 'ant-button-auto-width';
const buttonStyles = css `
	// Increase font weight on btn to semi-bold
	.ant-btn {
		font-weight: 500;
		position: relative;

		&:hover {
			border-color: ${color('primary')};
			color: ${color('primary')};
		}

		&.ant-btn-primary:not(:disabled) {
			background: ${color('primary')};
			border-color: ${color('primary')};
			color: ${color(['primary', '900'])};
		}

		&.ant-btn-link {
			color: ${color('primary')};

			:hover {
				border-color: transparent;
			}
		}

		&:not(.ant-btn-link, .ant-btn-text) {
			text-transform: uppercase;
		}

		&.ant-btn-sm {
			font-size: ${fontSize('sm')} !important;
		}

		&.ant-btn-text {
			display: flex;
			align-content: center;
			justify-content: center;
			padding: 0 !important;
			background-color: transparent !important;
			height: auto !important;
			line-height: ${lineHeight()} !important;
			&:hover span {
				text-decoration: underline !important;
			}
		}

		span {
			${screen.lte.md({
    flex: '0 0 auto',
    whiteSpace: 'pre-wrap',
    maxWidth: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
})}
		}

		${screen.lte.md({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
})}

		svg:not(:last-child) {
			flex: 0 0 auto;
			margin-right: ${margin('xxs')};
		}
	}

	.ant-btn:not(.${ANT_BUTTON_AUTO_WIDTH_CLASS_NAME}) {
		${screen.lte.xs({
    width: '100%',
})}

		span {
			${screen.lte.md({
    justifyContent: 'center',
    textAlign: 'center',
})}
		}
	}
`;
const inputStyles = css `
	.ant-input {
		:focus {
			border-color: ${color('primary')};
			box-shadow: 0 0 0 2px ${rgbaColor('primary', 0.2)};
		}
	}

	.ant-input-number-focused {
		border-color: ${color('primary')};
		box-shadow: 0 0 0 2px ${rgbaColor('primary', 0.2)};
	}

	.ant-input-affix-wrapper:focus,
	.ant-input-affix-wrapper-focused {
		border-color: ${color('primary')};
		box-shadow: 0 0 0 2px ${rgbaColor('primary', 0.2)};
	}

	.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover,
	.ant-input:hover {
		border-color: ${color('primary')};
	}
`;
export const ANT_SELECT_HIDDEN_DROPDOWN_IF_EMPTY_CLASS_NAME = 'ant-select-hidden-dropdown-if-empty';
const selectStyles = css `
	.ant-select {
		min-width: 220px;
		${screen.lte.xs({
    minWidth: '100%',
})}
	}

	.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
		border-color: ${color('primary')};
		box-shadow: 0 0 0 2px ${rgbaColor('primary', 0.2)};
	}

	.ant-select:not(.ant-select-disabled):hover .ant-select-selector {
		border-color: ${color('primary')};
	}

	.ant-select-dropdown-empty.${ANT_SELECT_HIDDEN_DROPDOWN_IF_EMPTY_CLASS_NAME} {
		display: none;
	}

	.ant-select-selection-overflow-item {
		max-width: 95%; /* fix to multiselect search input */
	}

	.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
		background-color: ${color(['primary', '800'])};
	}
`;
const listStyles = css `
	.ant-list {
		.ant-list-item {
			display: flex;

			:first-child {
				padding-top: 0;
			}

			${screen.lte.xs `
				flex-direction: column;
				align-items: stretch;
			`}
		}

		.ant-list-item-action {
			display: flex;
			margin-left: ${margin('sm')};

			li {
				padding: 0;

				&:not(:last-child) {
					margin-right: ${margin('xs')};

					${screen.lte.xs `
						margin-right: 0;
						margin-bottom: ${margin('xs')};
					`}
				}
			}

			${screen.lte.xs `
				width: 100%;
				margin-left: 0;
				margin-top: ${margin('sm')};
				flex-direction: column;
				align-items: stretch;
			`}
		}

		.ant-list-item-action-split {
			display: none;
		}
	}
`;
const drawerStyles = css `
	.ant-drawer {
		z-index: 999;
	}

	.ant-drawer-header {
		padding: ${padding('sm', 'xs')};
	}

	.ant-drawer-body {
		padding: ${padding('xxl', 'lg', 'lg', 'lg')};
	}

	.ant-drawer-footer {
		padding: ${padding('lg', 0, 'lg', 0)};
	}

	.ant-drawer-header + .ant-drawer-body {
		padding-top: ${padding('lg')};
	}
`;
export const ANT_DROPDOWN_BUTTON_ROUND_CLASS_NAME = 'ant-dropdown-button-round';
const dropdownStyles = css `
	.ant-btn-group,
	.ant-btn-group-sm {
		${screen.lte.xs({
    width: '100%',
})}
	}

	.ant-btn-group-sm.${ANT_DROPDOWN_BUTTON_ROUND_CLASS_NAME} {
		.ant-btn:first-child:not(:last-child) {
			border-top-left-radius: 50px;
			border-bottom-left-radius: 50px;
		}

		.ant-btn:last-child:not(:first-child) {
			border-top-right-radius: 50px;
			border-bottom-right-radius: 50px;
		}
	}
`;
const tooltipStyles = css `
	.ant-tooltip {
		${screen.lte.xs `
			left: ${margin('md')} !important;
			right: ${margin('md')} !important;
			max-width: calc(100vw - 2 * ${margin('md')}) !important;
		`}

		${screen.lte.md `
			left: 8.33vw !important;
			right: 8.33vw !important;
			max-width: 83.34vw !important;
		`}

		.ant-tooltip-inner {
			font-size: ${fontSize('sm')};
		}
	}
`;
const popoverStyles = css `
	.ant-popover {
		${screen.lte.xs `
			left: ${margin('md')} !important;
			right: ${margin('md')} !important;
			max-width: calc(100vw - 2 * ${margin('md')}) !important;
		`}
	}
`;
const skeletonStyles = css `
	.ant-skeleton,
	.ant-skeleton-content,
	.ant-skeleton-title,
	.ant-skeleton-paragraph,
	.ant-skeleton-paragraph li {
		display: block;
		max-width: 100%;
	}
`;
const collapseStyles = css `
	.ant-collapse-header {
		${screen.lte.xs `
			margin: 0;
			width: 100%;
		`}
	}
`;
const uploadStyles = css `
	.ant-upload.ant-upload-drag .ant-upload {
		padding: ${padding('md')};
	}

	.ant-upload-list-item .ant-upload-list-item-card-actions-btn {
		${screen.lte.md `
			opacity: 1;
		`}
	}
`;
const pickerStyles = css `
	.ant-picker {
		width: 100%;
	}

	.ant-picker-focused {
		border-color: ${color('primary')};
		box-shadow: 0 0 0 2px ${rgbaColor('primary', 0.2)};
	}

	.ant-picker-range .ant-picker-active-bar {
		background: ${color('primary')};
	}

	.ant-picker:hover,
	.ant-picker-focused {
		border-color: ${color('primary')};
	}

	.ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
		border-color: ${color('primary')};
	}
`;
const tabStyles = css `
	.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn,
	.ant-tabs-tab:hover {
		color: ${color('primary')};
	}

	.ant-tabs-ink-bar {
		background: ${color('primary')};
	}
`;
export default css `
	${typographyStyles}
	${layoutStyles}
	${tableStyles}
	${stepsStyles}
	${cardStyles}
	${avatarStyles}
	${modalStyles}
	${formStyles}
	${radioStyles}
	${checkboxStyles}
	${switchStyles}
	${buttonStyles}
	${inputStyles}
	${selectStyles}
	${listStyles}
	${drawerStyles}
	${dropdownStyles}
	${tooltipStyles}
	${popoverStyles}
	${skeletonStyles}
	${collapseStyles}
	${uploadStyles}
	${pickerStyles}
	${tabStyles}
`;
